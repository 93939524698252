<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-uppercase">
            Nombre
          </th>
          <th class="text-center text-uppercase">
            Apellido
          </th>
          <th class="text-center text-uppercase">
            Usuario
          </th>
          <th class="text-center text-uppercase">
            Email
          </th>
          <th class="text-center text-uppercase">
            Rol
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in listUser"
          :key="item.id"
        >
          <td>{{ item.nombre }}</td>
          <td class="text-center">
            {{ item.apellido }}
          </td>
          <td class="text-center">
            {{ item.userlogin }}
          </td>
          <td class="text-center">
            {{ item.email }}
          </td>
          <td class="text-center">
            {{ item.rol }}
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  setup() {
    const listUser = [
      {
        id: 1,
        nombre: 'Juanjo',
        apellido: 'Villarruel',
        userlogin: 'juanjo',
        email: 'juanjo@gmail.com',
        rol: 'Administrador',
      },
      {
        id: 2,
        nombre: 'Carlos',
        apellido: 'Barrientos',
        userlogin: 'carlos',
        email: 'carlosbarr@gmail.com',
        rol: 'Encargado',
      },
      {
        id: 3,
        nombre: 'Elio',
        apellido: 'Herrera',
        userlogin: 'eherrera',
        email: 'herreraelio@gmail.com',
        rol: 'Administrador',
      },
      {
        id: 4,
        nombre: 'Natalia',
        apellido: 'Gómez',
        userlogin: 'ngomez',
        email: 'gomeznaty@gmail.com',
        rol: 'Cobrador',
      },
      {
        id: 5,
        nombre: 'Federico',
        apellido: 'Toledo',
        userlogin: 'fedetol',
        email: 'fedetoledo@hotmail.com',
        rol: 'Gerente',
      },
      {
        id: 6,
        nombre: 'María Belén',
        apellido: 'Manzoni',
        userlogin: 'mbmanzoni',
        email: 'MariaManzoni@hotmail.com',
        rol: 'Cobrador',
      },
    ]

    return {
      listUser,
    }
  },
}
</script>
