<template>
  <v-row>
    <!-- basic -->
    <v-col cols="12">
      <v-card>
        <v-card-title>Listado de Usuarios</v-card-title>
        <SimpleTable></dSimpleTable>
        </simpletable>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import SimpleTable from './SimpleTable.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    SimpleTable,
  },
  setup() {
    return {}
  },
}
</script>
